// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import { Link } from "gatsby"

import "./../../scss/main.scss"

export default class Nav extends React.Component {
  render() {
    return (
      <footer class="footer" style={{ position: "sticky", bottom: 0 }}>
        <div class="container">
          <div class="row row-copyright d-flex flex-row justify-content-center align-items-center text-center">
            <span>&copy; 2020 Storylava</span>
            <Link to="/about/terms/">Terms</Link>
            <Link to="/about/privacy/">Privacy</Link>
          </div>
        </div>
      </footer>
    )
  }
}
